import { graphql } from 'gatsby';
import { Box } from 'theme-ui';
import Layout from '../components/Layout';
import Link from '../components/Link';

export default function ListPage({ data }) {
  return (
    <Layout>
      {data.allSanityPage.nodes.map(({ gatsbyPath, title }) => (
        <Box sx={{ gridColumn: 'text' }} key={gatsbyPath}>
          <Link to={gatsbyPath}>{title}</Link>
        </Box>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query ListPage {
    allSanityPage {
      nodes {
        gatsbyPath(filePath: "/{sanityPage.slug__current}")
        title
      }
    }
  }
`;
